import React from "react"
import Layout from "../components/layouts/Layout.js"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import Button from "../components/Button.js"
import * as styles from "../stylesheets/pages/wisp.module.scss"

const Wisp = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicWisp {
        data {
          description {
            richText
          }
          title {
            text
          }
          main_image {
            alt
            url
          }
          faq {
            answer {
              richText
            }
            question {
              text
            }
            icon {
              alt
              url
            }
          }
        }
      }
    }
  `).prismicWisp.data

  const faq = data.faq.map((faq, idx, array) => {
    return (
      <div key={idx} className={styles.faq}>
        <img src={faq.icon.url} alt={faq.icon.alt} className={styles.faqIcon} />
        <div>
          <h2 className={styles.faqQuestion}>{faq.question.text}</h2>
          <div>
            <RichText
              render={faq.answer.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
          {idx < array.length - 1 && <div className={styles.faqDivider} />}
        </div>
      </div>
    )
  })
  return (
    <Layout
      title="Written Information Security Policy (WISP) - TechCollective"
      description="TechCollective is a worker-owned MSP, providing IT and tech support to businesses. We can help you build and implement your WISP security policy."
    >
      <div className={styles.container}>
        <img
          src={data.main_image.url}
          alt={data.main_image.alt}
          className={styles.mainImage}
        />
        <div className={styles.bodyContainer}>
          <h1 className={styles.title}>{data.title.text}</h1>
          <div className={styles.description}>
            <RichText
              render={data.description.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>

          <Button text="Contact Us" internalLink="/contact" />
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.faqContainer}>{faq}</div>
        <Button text="Contact Us" internalLink="/contact" />
      </div>
    </Layout>
  )
}

export default Wisp
