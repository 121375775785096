// extracted by mini-css-extract-plugin
export var bodyContainer = "wisp-module--bodyContainer--08d1a";
export var bottomContainer = "wisp-module--bottomContainer--2b760";
export var container = "wisp-module--container--f2f0f";
export var description = "wisp-module--description--32c60";
export var faq = "wisp-module--faq--b7f98";
export var faqContainer = "wisp-module--faqContainer--18410";
export var faqDivider = "wisp-module--faq-divider--0f97f";
export var faqIcon = "wisp-module--faq-icon--b9c2e";
export var faqQuestion = "wisp-module--faq-question--a1b64";
export var mainImage = "wisp-module--mainImage--65a73";
export var title = "wisp-module--title--8a6d0";